html, body {
    background-color: #eeeeee;
    font-family: sans-serif;
    font-size: 9pt;
}

/***** Structure *****/

#content {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 8px;
}

#footer {
    font-size: 8pt;

    a { /* Until Bootstrap is implemented */
        color: #0275d8;
        text-decoration: none;
    }
}

/***** Typography *****/

h1 {
    font-size: 14pt;
    margin-top: 10px;
    margin-bottom: 3px;
}

h2 {
    font-size: 11pt;
    margin-top: 10px;
    margin-bottom: 3px;
}

h3 {
    font-family: sans-serif;
    font-size: 11pt;
    margin-top: 10px;
    margin-bottom: 3px;
}

hr {
    color: #eeeeee;
    height: 1px;
}

/***** Menu *****/

#menu {
    -moz-border-radius: 8px;
    background: #4090cd;
    background: -moz-linear-gradient(#a9c2d5, #4090cd);
    background: -ms-linear-gradient(#a9c2d5, #4090cd);
    background: -o-linear-gradient(#a9c2d5, #4090cd);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0, #a9c2d5),color-stop(1, #4090cd));
    background: -webkit-linear-gradient(#a9c2d5, #4090cd);
    background: linear-gradient(#a9c2d5, #4090cd);
    border-radius: 8px;
    list-style: none;
}

#menu a {
    color: #dfdfdf;
    /* text-transform: uppercase; */
    font: bold 16px/25px Arial, Helvetica;
    text-decoration: none;
    text-shadow: 0 1px 0 #000;
}

#menu li:hover > a {
    color: #ffffff;
}

*html #menu li a:hover /* IE6 */  {
    color: #ffffff;
}

/* Sub-menu */

#menu ul {
    -moz-border-radius: 5px;
    -moz-box-shadow: 0 0 2px rgba(255,255,255,.5);
    -webkit-box-shadow: 0 0 2px rgba(255,255,255,.5);
    background: #2e6e9e;
    background: -moz-linear-gradient(#2e6e9e, #2e6e9e);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0, #2e6e9e),color-stop(1, #2e6e9e));
    background: -webkit-linear-gradient(#2e6e9e, #2e6e9e);
    background: -o-linear-gradient(#2e6e9e, #2e6e9e);
    background: -ms-linear-gradient(#2e6e9e, #2e6e9e);
    background: linear-gradient(#2e6e9e, #2e6e9e);
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(255,255,255,.5);
    list-style: none;
}

#menu ul li {
    -moz-box-shadow: 0 1px 0 #111111, 0 2px 0 #777777;
    -webkit-box-shadow: 0 1px 0 #111111, 0 2px 0 #777777;
    box-shadow: 0 1px 0 #111111, 0 2px 0 #777777;
}

#menu ul li:last-child {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#menu ul a {
    font-size: 12px;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
}

#menu ul a:hover {
    background: #a9c2d5;
    background: -moz-linear-gradient(#a9c2d5, #4090cd);
    background: -webkit-gradient(linear, left top, left bottom, from(#a9c2d5), to(#4090cd));
    background: -webkit-linear-gradient(#a9c2d5, #4090cd);
    background: -o-linear-gradient(#a9c2d5, #4090cd);
    background: -ms-linear-gradient(#a9c2d5, #4090cd);
    background: linear-gradient(#a9c2d5, #4090cd);
    text-shadow: 0 1px 0 #000;
}

#menu ul li:first-child > a {
    -moz-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}

#menu ul li:first-child > a:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #2e6e9e;
}

#menu ul ul li:first-child a:after {
    border-left: 0;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    border-right: 8px solid #2e6e9e;
}

#menu ul li:first-child a:hover:after {
    border-bottom-color: #a9c2d5;
}

#menu ul ul li:first-child a:hover:after {
    border-right-color: #6fa6d0;
    border-bottom-color: transparent;
}

#menu ul li:last-child > a {
    -moz-border-radius: 0 0 5px 5px;
    border-radius: 0 0 5px 5px;
}

/* Utils */

.box-close {
    background: #605F61;
    border: 1px solid #AEAEAE;
    border-radius: 30px;
    color: #fff;
    cursor:pointer;
    display: inline-block;
    float:right;
    font-size: 25px;
    font-weight: bold;
    line-height: 0;
    margin-right:-12px;
    margin-top:-12px;
    padding: 10px 3px;
}
.box-close:before{
    content: "×";
}

.right {
    float: right;
}

div[contenteditable="true"]:focus {
    background: #ffffff;
    border: 1px solid #acb6bf;
}

div[contenteditable="true"]:hover {
    background: #ffffff;
}

/* Tables */
.tableComponent_container {

}
    .tableComponent_header {
        background: #5c9ccc url(images/ui-bg_gloss-wave_55_5c9ccc_500x100.png) 50% 50% repeat-x;
        border: 1px solid #4297d7;
        border-bottom: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        color: #fff;
        padding: 5px;
    }

    .tableComponent {
        border: 1px solid #c5dbec;
        border-collapse: collapse;
        border-spacing: 0px;
        clear: both;
        margin: 0 auto;
        width: 100%;
    }
        .tableComponent thead tr {
            background: #E2E4FF;
        }

        .tableComponent thead th {
            background: #dfeffc url(images/ui-bg_glass_85_dfeffc_1x400.png) 50% 50% repeat-x;
            border-left: 1px solid #c5dbec;
            border-right: 1px solid #c5dbec;
            color: #2e6e9e;
            cursor: pointer;
            font-weight: bold;
            padding: 3px 0 3px 10px;
            text-align: left;
        }

        .tableComponent tr {
            border-bottom: 1px solid #ddd;
        }

        .tableComponent tr:last-child {
            border-bottom: 0;
        }

    .tableComponent_footer {
        background: #5c9ccc url(images/ui-bg_gloss-wave_55_5c9ccc_500x100.png) 50% 50% repeat-x;
        border: 1px solid #4297d7;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top: 0;
        color: #fff;
        padding: 5px;
    }



table tr:hover {
    background-color: #F5F5F5;
}

table tr.selected {
    background-color: #E2E4FF;
}

table td {
    border-right: 1px solid #DDDDDD;
}

table td:last-child {
    border-right: 0;
}

/* Widget */
.widgetPage {
    /*overflow: hidden;*/
}

.widget .inner {
    /*background: #2b3847;*/
    border: 1px solid #4297d7;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 0;
}
    .widgetHeader {
        background: #5c9ccc url("images/ui-bg_gloss-wave_55_5c9ccc_500x100.png") 50% 50% repeat-x;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: #fff;
        font-weight: bold;
        height: 35px;
        line-height: 35px;
        padding-left: 20px;
    }
    .accordionHeader {
        border-radius: 10px;
    }
        .searchFilter {
            float: right;
            font-family: Lucida Grande,Lucida Sans,Arial,sans-serif;
            font-size: 1em;
            font-weight: normal;
            margin-right: 5px;
            text-align: right;
        }
            .searchFilter input {
                margin-bottom: 0;
            }

    .widgetBody {
        background: transparent;
        border-radius: 0 0 2px 2px;
        box-shadow: none;
        min-height: 60px;
        overflow: hidden;
        padding: 15px;
    }
    .widgetBody > div {
        width: 100%;
    }
    .widgetBody > div.spinner {
        width: 20px;
    }

    .widgetFooter {
        background: #5c9ccc url(images/ui-bg_gloss-wave_55_5c9ccc_500x100.png) 50% 50% repeat-x;
        /*border-top: 1px solid #4297d7;*/
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        color: #FFFFFF;
        font-style: italic;
        height: 30px;
        line-height: 30px;
        padding-left: 20px;
        text-align: center;
    }
        .widgetFooter a {
            color: #FFFFFF;
            font-style: italic;
        }

/* Widget types */

.accordionWidget {
    min-height: 0;
}

.emptyWidget {
    min-height: 0px;
    padding: 5px;
}

.gaugeWidget {
    /*font-size: 200%;
    font-weight: bolder;
    text-align: center;*/
}

.listWidget {
    font-size: 200%;
    font-weight: bolder;
    text-align: center;
}

.mapWidget {
    padding: 10px;
}
    .mapWidget .leafletMap {
        height: 300px;
        width: 100%;
    }

.metricWidget {
    font-size: 200%;
    font-weight: bolder;
    text-align: center;
}

.tableWidget {
    padding: 0;
    width: 100%;
}
    .tableWidget .innerHeader {
        background: #dfeffc url(images/ui-bg_glass_85_dfeffc_1x400.png) 50% 50% repeat-x;
        border: 1px solid #c5dbec;
        color: #2e6e9e;
        font-weight: bold;
        padding: 5px;
    }
    .tableWidget table {
        border-collapse: collapse;
        width: 100%;
    }
    .tableWidget thead th {
        background: #dfeffc url(images/ui-bg_glass_85_dfeffc_1x400.png) 50% 50% repeat-x;
        border: 1px solid #c5dbec;
        color: #2e6e9e;
        font-weight: bold;
    }

    .tableWidget tbody {
        /*border-left: 1px solid #CCCCCC;
        border-right: 1px solid #CCCCCC;*/
    }

    .tableWidget th, td {
        padding: 5px 5px;
        text-align: left;
    }

    .tableWidget tr {
        border-bottom: 1px solid #ddd;
    }

    .tableWidget tr:hover {
        background-color: #f5f5f5
    }

    .tableWidget tr:last-child {
        border-bottom: 0;
    }

/* components/treeNode */

.treeNodeContainer {
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    border: 1px solid #dddddd;
    max-height: 240px;
    overflow: auto;
    width: 400px;
}

.treeNode {
    list-style-image: url("../images/tag.png");
    cursor: pointer;
}
    .tree-node-icon-0 {	list-style-image: url("../images/tag-color-0.png"); }
    .tree-node-icon-1 {	list-style-image: url("../images/tag-color-1.png"); }
    .tree-node-icon-2 {	list-style-image: url("../images/tag-color-2.png"); }
    .tree-node-icon-3 {	list-style-image: url("../images/tag-color-3.png"); }
    .tree-node-icon-4 {	list-style-image: url("../images/tag-color-4.png"); }
    .tree-node-icon-5 {	list-style-image: url("../images/tag-color-5.png"); }
    .tree-node-icon-6 {	list-style-image: url("../images/tag-color-6.png"); }
    .tree-node-icon-7 {	list-style-image: url("../images/tag-color-7.png"); }

    .treeNode .node {
        cursor: pointer;
    }

    .treeNode li {
        list-style-type: none;
        position: relative;
    }

    .treeNode li span {
        /*cursor: default;*/
    }
    .treeNode li span.nodeIcon {
        left: -16px;
        position: absolute;
        top: 1px;
    }
    .treeNode li span.nodeTitle {
        margin-left: 2px;
    }

/* Colors */

.primary-color-bluegrey {
    color: #607D8B;
}
.primary-fill-bluegrey {
    fill: #607D8B;
}
.secondary-color-bluegrey {
    color: #455A64;
}
.secondary-fill-bluegrey {
    fill: #455A64;
}
.primary-color-green {
    color: #4CAF50;
}
.primary-fill-green {
    fill: #4CAF50;
}
.secondary-color-green {
    color: #388E3C;
}
.secondary-fillr-green {
    fill: #388E3C;
}
.primary-color-lightblue {
    color: #03A9F4;
}
.primary-fill-lightblue {
    fill: #03A9F4;
}
.secondary-color-lightblue {
    color: #0288D1;
}
.secondary-fill-lightblue {
    fill: #0288D1;
}
.primary-color-red {
    color: #FF5252;
}
.primary-fill-red {
    fill: #FF5252;
}
.secondary-color-red {
    color: #F44336;
}
.secondary-fill-red {
    fill: #F44336;
}
.primary-color-yellow {
    color: #FFEB3B;
}
.primary-fill-yellow {
    fill: #FFEB3B;
}
.secondary-color-yellow {
    color: #FBC02D;
}
.secondary-fill-yellow {
    fill: #FBC02D;
}